<template>
  <AIconButton
    :icon-name="iconName"
    :class="{ active }"
    class="favorite"
    :size="size"
    @click="$emit('toggleFavorite')"
  />
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'

import type { IconSize } from '@/utils/icon-types-static'
import type { PropType } from 'vue'
import type { IconName } from '@/utils/icon-types'

import AIconButton from '@/components/atoms/IconButton/AIconButton.vue'

type FavoriteOutlineColor = 'light' | 'grey' | 'primary'

export default defineComponent({
  name: 'AFavorite',
  components: { AIconButton },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    size: {
      type: String as PropType<IconSize>,
      default: undefined
    },
    outlineColor: {
      type: String as PropType<FavoriteOutlineColor>,
      default: undefined
    }
  },
  emits: ['toggleFavorite'],
  setup (props) {
    const iconName = computed(() => {
      return `favorite-outline${props.outlineColor ? `-${props.outlineColor}` : ''}` as IconName
    })

    return { iconName }
  }
})
</script>

<style lang="postcss">
.favorite.icon-button.active::before {
  background-image: url("/assets/icons/general.svg#favorite");
  transform: translateX(0);
}

@media (hover: hover) and (--screen-lg) {
  .favorite.icon-button:hover::before {
    background-image: url("/assets/icons/general.svg#favorite-outline--hover");
    transform: translateX(0);
  }

  .favorite.icon-button.active:hover::before {
    background-image: url("/assets/icons/general.svg#favorite--hover");
    transform: translateZ(0);
  }
}

/* -webkit-transform: fix for safari */
</style>
