<template>
  <div
    v-if="areOptionsVisible"
    class="outer"
    @click.self="areOptionsVisible = false"
  />
  <div
    class="dropdown"
    :class="{
      [`dropdown--${size}`]: size,
      opened: areOptionsVisible,
    }"
  >
    <button
      :disabled="disabled"
      aria-label="Show Dropdown elements"
      class="i-arrow-drop"
      @click="toggleDropdown"
    >
      {{ optionValues[modelValue]?.[1] }} <span v-if="withUnit">{{ $t('molecules.cart-product-item.pcs') }}</span>
    </button>
    <ul v-if="areOptionsVisible">
      <li
        v-for="([ key, value ], idx) in optionValues"
        :key="idx"
        :class="{ active: modelValue === idx, 'i-check-primary': modelValue === idx }"
        @click="selectItem(key, idx, value)"
      >
        {{ value }}
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue'
import type { PropType } from 'vue'

export type DropdownSize = 'sm' | 'lg'

const props = defineProps({
  size: {
    type: String as PropType<DropdownSize>,
    default: undefined
  },
  options: {
    type: Object as PropType<Record<any, any>>,
    default: () => ({})
  },
  modelValue: {
    type: Number,
    default: 0
  },
  disabled: {
    type: Boolean,
    default: false
  },
  withUnit: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['select-option', 'update:modelValue'])

const optionValues = computed(() => Object.entries(props.options))
const areOptionsVisible = ref(false)

const toggleDropdown = (): void => {
  areOptionsVisible.value = !areOptionsVisible.value
}

const selectItem = (key: string, idx: number, value: any): void => {
  emit('update:modelValue', idx)
  emit('select-option', { key, value })
  toggleDropdown()
}
</script>

<style lang="postcss">
.dropdown {
  position: relative;
  width: 16.5rem;
}

.dropdown > button {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 2.5rem;
  padding: calc(var(--spacer-3xs) - 1px) var(--spacer-lg) calc(var(--spacer-3xs) - 1px) var(--spacer-xs);
  border: 1px solid var(--color-neutral-400);
  border-radius: var(--border-radius-xs);
  text-align: left;

  &::before {
    position: absolute;
    top: calc(50% - var(--spacer-2xs));
    right: var(--spacer-3xs);
    transition: transform 0.2s;
    transform: rotate(180deg);
  }

  @mixin text-base;
}

.dropdown > button[disabled] {
  background-color: var(--color-neutral-300);
  color: var(--color-neutral-600);

  &::before {
    background-image: url("/assets/icons/general.svg#arrow-drop-grey");
  }
}

.dropdown.opened > button::before {
  transform: rotate(0);
}

.dropdown > button:focus {
  box-shadow: var(--shadow-base);
  outline: none;
}

@media (hover: hover) and (--screen-lg) {
  .dropdown button:hover::before {
    transform: rotate(180deg);
  }
}

.dropdown > ul {
  position: absolute;
  top: 3.125rem;
  left: 0;
  z-index: var(--z-dropdown);
  overflow-y: scroll;
  width: 100%;
  max-height: calc(22.5rem + 1px);
  margin: 0;
  padding: var(--spacer-3xs) 0;
  border-radius: var(--border-radius-xs);
  background: var(--color-white);
  box-shadow: var(--shadow-base);
  list-style-type: none;

  @mixin text-base;
}

.dropdown > ul::-webkit-scrollbar {
  width: var(--spacer-5xs);
  background-color: transparent;
}

.dropdown > ul::-webkit-scrollbar-thumb {
  background-color: var(--color-neutral-400);
}

.dropdown > ul li {
  position: relative;
  margin: 0;
  padding: 0.375rem var(--spacer-base) 0.375rem var(--spacer-xs);
  cursor: pointer;
}

@media (hover: hover) and (--screen-lg) {
  .dropdown > ul li:hover {
    background-color: var(--primary-focus-light);
  }
}

.dropdown > ul li:active {
  background-color: var(--primary-focus-light);
}

.dropdown > ul li.i-check-primary::before {
  position: absolute;
  top: calc(50% - var(--spacer-2xs));
  right: var(--spacer-3xs);
}

.outer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 90;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
}

.dropdown--sm {
  width: 12.5rem;

  & > button {
    border: none;
    color: var(--color-neutral-600);
    text-align: right;
  }

  & > button:focus {
    box-shadow: none;
    outline: none;
  }

  & > ul {
    top: 2.75rem;
  }

  @mixin text-sm;
}

.dropdown--lg {
  width: 7rem;
  height: 3.5rem;

  & > ul {
    top: 4rem;
  }
}
</style>
