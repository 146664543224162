<template>
  <div
    class="text-collapse"
    :class="{
      [ `text-collapse--${size}` ]: size,
      multiple,
      opened: readMore,
    }"
  >
    <div
      v-if="text?.length"
      ref="textEl"
      @click.prevent="onHtmlEntityClick"
      v-html="DOMPurify.sanitize(text)"
    />
    <div
      v-else
      ref="textEl"
    >
      <slot />
    </div>
    <AActionButton
      v-if="hasBtn"
      :size="size"
      underline
      @click="toggleText"
    >
      {{ readMore ? $t('other.hidden') : (readMoreText || $t('molecules.text-collapse.read-more')) }}
    </AActionButton>
  </div>
</template>

<script setup lang="ts">
import { defineComponent, nextTick, onMounted, onUnmounted, ref, watch } from 'vue'
import { useResizeObserver, useMutationObserver } from '@vueuse/core'
import DOMPurify from 'isomorphic-dompurify'
import { useNavigate } from '@/composables/useNavigate'

import type { PropType } from 'vue'
import type { UseResizeObserverReturn } from '@vueuse/core'

import AActionButton from '@/components/atoms/ActionButton/AActionButton.vue'

defineComponent({ name: 'MTextCollapse' })

const props = defineProps({
  size: {
    type: String as PropType<'sm'>,
    default: undefined
  },
  text: {
    type: String,
    default: ''
  },
  lines: {
    type: Number,
    default: 3
  },
  readMoreText: {
    type: String,
    default: ''
  }
})

const emit = defineEmits(['collapse'])

const { blankPush } = useNavigate()

const hasBtn = ref(false)
const readMore = ref(false)
let scrollPosition = 0

const toggleText = (): void => {
  readMore.value = !readMore.value
  scrollIntoView()
  emit('collapse', readMore.value)
}

const scrollIntoView = (): void => {
  const top = textEl.value?.getBoundingClientRect().top ?? 0
  if (!readMore.value && top < 0) {
    window.scrollTo({ top: scrollPosition, behavior: 'smooth' })
  } else {
    scrollPosition = window.scrollY ?? 0
  }
}

const textEl = ref<HTMLDivElement>()
const multiple = ref(false)

let textElWidth = 0

const checkTextHeight = (): void => {
  if (readMore.value) {
    toggleText()
  }

  const textOffsetHeight = textEl.value?.offsetHeight ?? 0
  const textHeight = textEl.value?.scrollHeight ?? 0

  hasBtn.value = textHeight > textOffsetHeight
}

const onHtmlEntityClick = (e: MouseEvent): void => {
  const targetAnchor = (e.target as HTMLElement)?.closest('a')
  if (targetAnchor) {
    const href = targetAnchor.getAttribute('href')
    if (!href) { return }

    if (href.includes('http')) {
      window.open(href, '_blank')
    } else {
      blankPush(href, e, !href.includes('html'))
    }
  }
}

watch(textEl, () => {
  multiple.value = (textEl.value?.children.length ?? 0) > 1
  textElWidth = textEl.value?.offsetWidth ?? 0

  nextTick(() => checkTextHeight())
})

let observer: UseResizeObserverReturn | null = null
onMounted(() => {
  observer = useResizeObserver(textEl, (entries) => {
    window.requestAnimationFrame(() => {
      const width = Math.round(entries[0].contentRect.width)
      if (Math.abs(textElWidth - width) > 1) {
        textElWidth = width
        checkTextHeight()
      }
    })
  })

  if (!props.text?.length) {
    useMutationObserver(textEl, (mutation) => {
      if (mutation) {
        checkTextHeight()
      }
    }, { childList: true })
  }
})

onUnmounted(() => observer?.stop())
</script>

<style lang="postcss">
.text-collapse {
  --text-collapse-mt: var(--spacer-2xs);
  --text-collapse-lines: v-bind(lines);
  --text-collapse-height: calc(1.5rem * var(--text-collapse-lines));

  width: 100%;
  color: var(--color-text-dark);

  & > div:first-child {
    display: -webkit-box;
    overflow: hidden;
    letter-spacing: var(--letter-spacing-base);
    -webkit-line-clamp: var(--text-collapse-lines);
    -webkit-box-orient: vertical;

    & li {
      display: inline-block;
    }

    & + .action-btn {
      margin-top: var(--text-collapse-mt);
    }
  }

  &.multiple > div:first-child {
    /* fix for safari */

    @supports (-webkit-touch-callout: none) {
      display: block;
      height: var(--text-collapse-height);
    }

    /* fix for firefox */
    @supports not (contain-intrinsic-size: none) {
      height: var(--text-collapse-height);
    }
  }

  &.opened {
    & > div:first-child {
      display: block;

      /* fix for safari */
      @supports (-webkit-hyphens: none) {
        height: auto !important;
      }

      @supports (-webkit-touch-callout: none) {
        height: auto !important;
      }

      /* fix for firefox */
      @supports not (contain-intrinsic-size: none) {
        height: auto !important;
      }
    }
  }

  @mixin text-base;
}

.text-collapse--sm {
  --text-collapse-mt: var(--spacer-4xs);

  & .action-btn {
    height: 1.5rem;
  }

  @mixin text-sm;
}
</style>
