<template>
  <SegmentedControls
    v-model="currentValue"
    :controls="controls"
    class="m-view-controls"
  />
</template>

<script setup lang="ts">
import './view-controls.css'
import { computed } from 'vue'

import type { PropType } from 'vue'
import type { SnippetView } from '@/composables/product'
import type { SegmentedControl } from '@/components/ui-kit/m-segmented-control/types'

import SegmentedControls from '@/components/ui-kit/m-segmented-control/SegmentedControl.vue'

const props = defineProps({
  modelValue: {
    type: String as PropType<SnippetView>,
    required: true
  }
})

const emit = defineEmits(['update:modelValue'])

const controls: SegmentedControl[] = [
  { icon: 'view-list' },
  { icon: 'view-grid' },
  { icon: 'view-big' }
]

const direction: Record<number, SnippetView> = {
  0: 'list',
  1: 'grid',
  2: 'big'
}

const swapDict = (obj: { [s: string]: unknown } | ArrayLike<unknown>): Record<string, string | number> =>
  Object.fromEntries(Object.entries(obj).map(a => a.reverse()))

const currentValue = computed({
  get: () => +swapDict(direction)[props.modelValue],
  set: (val: number) => emit('update:modelValue', direction[val])
})
</script>
