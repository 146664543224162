<template>
  <div
    class="info-description"
    :class="sizeClass"
  >
    <ul>
      <li
        v-for="(point, idx) in region"
        :key="point.code"
      >
        <div
          v-if="idx !== 0"
          class="description__dot"
        />
        <ALink
          v-if="point.path && !point.isClosed"
          :href="point.path !== currentRoute && size !== 'sm' && size !== 'xs' ? getUrl(point.path) : ''"
          class="info-description__value"
          :class="getIconClass(idx)"
        >
          {{ point.name }}
        </ALink>
        <span
          v-else
          class="info-description__value"
          :class="[getIconClass(idx), point.path && size !== 'sm' && size !== 'xs' ? 'link-hidden' : '']"
          @click="point.path && size !== 'sm' && size !== 'xs' ? $router.push(getUrl(point.path)) : null"
        >
          {{ point.name }}
        </span>
      </li>
    </ul>
    <ul
      ref="descriptionRef"
      :class="
        isColumn
          ? 'info-description__column'
          : `info-description__row${!isRowButtonClicked ? ' with-button' : ''} ${isRowButtonShow ? 'show-btn' : ''}`
      "
    >
      <AIconButton
        v-show="isRowButtonShow && !isRowButtonClicked"
        size="2xs"
        icon-name="more"
        variant="secondary"
        @click="isRowButtonClicked = true"
      />
      <li
        v-for="[ key, value ] in characteristicsData"
        :key="key"
        :class="withClasses ? key === 'category' ? 'info-category' : key : undefined"
      >
        <template v-if="size !== 'xl'">
          <span v-show="isColumn">{{ getTitle(key) }}:&nbsp;</span>
          <div
            v-for="(item, idx) in value"
            :key="item.name"
          >
            <ALink
              v-if="item.path && !item.isClosed"
              :href="size !== 'sm' && size !== 'xs' && item.path !== currentRoute ? getUrl(item.path) : ''"
              class="info-description__value"
            >
              {{ item.name }}
            </ALink>
            <span
              v-else
              class="info-description__value"
              :class="item.path && size !== 'sm' && size !== 'xs' ? 'link-hidden' : ''"
              @click="item.path && size !== 'sm' && size !== 'xs' ? $router.push(getUrl(item.path)) : null"
            >
              {{ item.name }}
            </span>
            <span v-if="insertSeparator(idx, value.length)">{{ insertSeparator(idx, value.length) }}</span>
          </div>
        </template>
        <template v-else>
          <MParams
            :title="getTitle(key)"
            :description="value"
          />
        </template>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import {
  defineComponent,
  toRefs,
  computed,
  onMounted,
  onUnmounted,
  ref
} from 'vue'
import { useRoute } from 'vue-router'
import { useResizeObserver } from '@vueuse/core'
import { getAlpha2Code } from '@/utils/country-flags'
import { useNavigate } from '@/composables/useNavigate'
import { useConfig } from '@/stores/config'

import type { Ref, PropType } from 'vue'
import type { UseResizeObserverReturn } from '@vueuse/core'
import type { CharacteristicFormat } from '@/composables/product'

import ALink from '@/components/atoms/Link/ALink.vue'
import AIconButton from '@/components/atoms/IconButton/AIconButton.vue'
import MParams from '@/components/molecules/Params/MParams.vue'

export type InfoDescriptionSize = 'xl' | 'lg' | 'base' | 'sm' | 'xs'

defineComponent({ name: 'MDescription' })

const props = defineProps({
  countryName: {
    type: String,
    default: undefined
  },
  region: {
    type: Array as PropType<CharacteristicFormat[]>,
    default: () => []
  },
  size: {
    type: String as PropType<InfoDescriptionSize>,
    default: undefined
  },
  description: {
    type: Object as PropType<Record<string, CharacteristicFormat[]>>,
    default: () => ({})
  },
  withButton: Boolean,
  withClasses: Boolean
})

const { isExternalRegion } = useConfig()
const { getUrl } = useNavigate()
const { size } = toRefs(props)
const route = useRoute()

const isColumn = computed(() => size?.value === 'lg' || size?.value === 'xl')

const characteristicsData = computed<[string, CharacteristicFormat[]][]>(() => {
  const data = Object.entries(props.description)
  return !isColumn.value
    ? data.slice(0, 3)
    : data
})

const sizeClass = computed(() => {
  return size?.value ? `info-description--${size.value}` : {}
})

const insertSeparator = (idx: number, total: number): string =>
  idx + 1 < total && isColumn.value ? ', ' : ''

const countryISO = computed(() => {
  return props.countryName && getAlpha2Code(props.countryName)
})

const getTitle = (key: string): string => {
  return props.description[key][0].title
}

const getIconClass = (idx: number): string => {
  return countryISO.value !== undefined && idx === 0
    ? countryISO.value !== 'ua' || isExternalRegion.value
      ? `i-${countryISO.value}`
      : 'i-default'
    : ''
}

const descriptionRef: Ref<HTMLElement | null> = ref(null)
const isRowButtonShow = ref(false)
const isRowButtonClicked = ref(!props.withButton)
const currentRoute = route.fullPath.replace(/^\/|\/$/g, '')

let observer: UseResizeObserverReturn | null = null
onMounted(() => {
  if (!isRowButtonClicked.value) {
    observer = useResizeObserver(descriptionRef, () => {
      window.requestAnimationFrame(() => {
        if (descriptionRef.value === null) { return }

        isRowButtonShow.value = descriptionRef.value?.scrollWidth > descriptionRef.value?.offsetWidth
      })
    })
  }
})

onUnmounted(() => observer?.stop())
</script>

<style lang="postcss">
.info-description {
  --info-description-gradient-color: linear-gradient(
    90deg,
    rgb(255 255 255 / 0%) 0,
    var(--color-white) 85%
  );
  --info-description-icon-size: 1.5rem;

  position: relative;
  overflow: hidden;

  &__value {
    color: var(--info-description-color, var(--color-text-dark));
    font-weight: 500;
    white-space: nowrap;
    pointer-events: var(--info-description-events, auto);

    &.i-default::before {
      content: "";
    }

    &[class*="i-"]::before {
      align-self: center;
      width: var(--info-description-icon-size);
      height: var(--info-description-icon-size);
    }

    &.link-hidden {
      cursor: pointer;

      &:not(:hover) {
        color: var(--color-text-dark);
      }

      @media (hover: hover) and (--screen-lg) {
        &:hover {
          color: var(--color-blue-800);
        }
      }
    }
  }

  @mixin text-base;

  @media (hover: hover) and (--screen-lg) {
    & a.info-description__value:hover {
      color: var(--color-blue-800);
    }
  }
}

.info-description > ul {
  margin: 0;
  padding: 0;
  list-style-type: none;

  &:first-child {
    --info-description-gradient-width: var(--spacer-base);

    display: flex;
    align-items: center;
    justify-content: flex-start;

    & > li:first-child > .info-description__value {
      display: flex;
      gap: 0;

      &::before {
        display: inline-block;
        margin-right: var(--spacer-3xs);
        border-radius: var(--border-radius-full);
      }
    }

    & > span {
      display: var(--info-description-icon-display, inline-block);
      margin-right: var(--spacer-3xs);
    }
  }

  & li {
    display: inline-flex;
    align-items: center;
    margin-right: 0;
  }

  &:first-child > li + li::before {
    @mixin info-dots;
  }

  &.info-description__row {
    --info-description-gradient-width: var(--spacer-base);

    position: relative;
    overflow: hidden;
    max-height: var(--info-description-max-height);

    &.show-btn {
      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: var(--info-description-gradient-width);
        background: var(--info-description-gradient-color);
      }
    }

    &.with-button {
      white-space: nowrap;

      &::before {
        display: block;
      }

      & > .icon-button {
        position: absolute;
        top: var(--spacer-4xs);
        right: 0;
        display: flex;
        align-items: center;
        height: 1rem;
        padding: 0 var(--spacer-4xs);
        border-radius: var(--border-radius-2xs);
        background-color: var(--color-white);
      }

      @media (--screen-xs) {
        & > .icon-button {
          /* display: none; */
          background-color: var(--color-neutral-200);
        }
      }
    }
  }

  &.info-description__row > li {
    display: inline;

    &:not(:last-child) div:last-child::after {
      vertical-align: middle;

      @mixin info-dots;
    }

    & > div,
    & > div > .info-description__value {
      display: inline-flex;
      align-items: center;
    }

    & > div:not(:last-child) > .info-description__value::after {
      @mixin info-dots;
    }
  }

  &:first-child,
  &.info-description__row.with-button {
    --info-description-gradient-width: var(--spacer-3xl);

    position: relative;

    &.show-btn {
      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: var(--info-description-gradient-width);
        background: var(--info-description-gradient-color);
      }
    }
  }
}

.info-description {
  &:not(.info-description--sm):not(.info-description--xs) {
    @media (--screen-lg) {
      & > ul span.link {
        color: var(--color-text-dark) !important;
      }
    }
  }
}

.info-description > ul:not(:first-child) li {
  width: var(--info-description-info-width, 100%);
}

.info-description--lg > ul.info-description__column li {
  display: flex;
  white-space: pre-wrap;

  & > div {
    display: inline-flex;
  }
}

.info-description > ul li + li::before {
  display: var(--info-description-key-display, inline-block);
  transform: rotate(45deg);
}

.info-description > ul li > span:first-child {
  display: flex;
  color: var(--color-neutral-600);
  white-space: nowrap;
}

ul.info-description__column {
  --info-description-gradient-width: var(--spacer-base);

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: var(--spacer-3xs);
  white-space: normal;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: var(--info-description-gradient-width);
    background: var(--info-description-gradient-color);
  }

  & .icon-button {
    display: none;
  }

  & > li {
    display: flex;
    align-items: flex-start;

    & .info-description__value,
    & > a {
      color: var(--color-text-dark);
    }

    & + li {
      margin-top: var(--spacer-3xs);
    }

    @media (hover: hover) and (--screen-lg) {
      & > a:hover {
        color: var(--color-blue-800);
      }
    }
  }
}

.info-description--sm
> ul:first-child
> li:first-child
> .info-description__value::before {
  display: none;
}

.info-description
  > ul:first-child::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: var(--info-description-gradient-width);
    background: var(--info-description-gradient-color);
}

.info-description--lg {
  --info-description-dot-margin: var(--spacer-3xs);
}

.info-description--base {
  /* Initial restores default value */
  --info-description-color: initial;
  --info-description-info-width: auto;
  --info-description-dot-margin: var(--spacer-3xs);
  --info-description-key-display: none;
  --info-description-max-height: var(--spacer-4xl);
}

.info-description--sm {
  --info-description-color: var(--color-neutral-600);
  --info-description-info-width: auto;
  --info-description-icon-display: none;
  --info-description-dot-margin: var(--spacer-4xs);
  --info-description-dot-color: var(--color-neutral-400);
  --info-description-max-height: var(--spacer-3xl);
  --info-description-events: text;

  & .info-description__value {
    @mixin text-sm;
  }
}

.info-description--xs {
  --info-description-color: var(--color-neutral-600);
  --info-description-info-width: auto;
  --info-description-dot-margin: var(--spacer-4xs);
  --info-description-dot-color: var(--color-neutral-400);
  --info-description-max-height: var(--spacer-3xl);
  --info-description-events: text;
  --info-description-icon-size: 1rem;

  & .info-description__value {
    @mixin text-sm;
  }

  @mixin text-sm;
}

@define-mixin info-dots {
  content: "";
  display: inline-flex;
  align-self: center;
  width: var(--spacer-4xs);
  height: var(--spacer-4xs);
  margin: 0 var(--info-description-dot-margin, var(--spacer-3xs));
  border-radius: var(--border-radius-full);
  background: var(--info-description-dot-color, var(--color-neutral-500));
}

/*
  Local custom properties to customize info-description:
  --info-description-color
  --info-description-info-width
  --info-description-dot-margin
  --info-description-icon-display
  --info-description-info-key-display
  --info-description-dot-color

  Please note!
  This component implementation is incomplete. It maintains only BASE and 2XS
  sizes used in ProductCard component. Further work depends on product page
  design.
*/
</style>
