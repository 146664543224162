<template>
  <component
    :is="component"
    ref="radioGroupRef"
    class="radioGroup"
  >
    <slot :change="changeValue" />
  </component>
</template>

<script setup lang="ts">
import './radio-group.css'
import { defineComponent, ref, onMounted, watch } from 'vue'

defineComponent({ name: 'WsRadioGroup' })
const props = defineProps({
  modelValue: {
    type: Number,
    default: null
  },
  condition: {
    type: Boolean,
    default: false
  },
  component: {
    type: String as PropType<'div' | 'ul'>,
    default: 'div'
  }
})

const emit = defineEmits({ 'update:modelValue': val => !isNaN(Number(val)) })

const changeValue = (idx: number): void => {
  if (!props.condition && idx !== props.modelValue) {
    emit('update:modelValue', idx)
  }
}

const radioGroupRef = ref<HTMLElement>()

const changeActiveClass = (value: number, oldValue?: number): void => {
  if (oldValue !== undefined) {
    radioGroupRef.value?.children[oldValue]?.classList.remove('active')
  }
  radioGroupRef.value?.children[value]?.classList.add('active')
}

watch(() => props.modelValue, changeActiveClass)
onMounted(() => {
  changeActiveClass(props.modelValue)
})
</script>
