<template>
  <div class="m-segmented-control">
    <RadioGroup
      :key="statusValue"
      v-model="statusValue"
    >
      <template #default="{ change }">
        <button
          v-for="(control, index) in controls"
          :key="index"
          :class="{ [`i-${control.icon}`]: control.icon && index !== statusValue, [`i-${control.icon}--active`]: index === statusValue }"
          @click="change(index)"
        >
          {{ control.title }}
        </button>
      </template>
    </RadioGroup>
  </div>
</template>

<script setup lang="ts">
import './segmented-control.css'
import { computed } from 'vue'

import type { SegmentedControl } from './types'
import type { PropType } from 'vue'

import RadioGroup from '@/components/ui-kit/a-radio-group/RadioGroup.vue'

const props = defineProps({
  modelValue: {
    type: Number,
    default: null
  },
  controls: {
    type: Array as PropType<SegmentedControl[]>,
    default: undefined
  }
})

const emit = defineEmits(['update:modelValue'])

const statusValue = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value)
})
</script>
